<footer id="footer"
		[class.ym-clearfix]="user()"
		[class.unauthenticated]="!user()">
	<ng-container *ngIf="user(); else notLoggedIn">
		<div class="ym-hlist list">
			<ul>
				<li>
					<a routerLink="/workflow.do?to=home">
						{{ 'frame.footer.home' | msgKeyCached }}
					</a>
				</li>
				<li>
					<a routerLink="/workflow.do?from=menu&to=faq">
						{{ 'frame.footer.faq' | msgKeyCached }}
					</a>
				</li>
				<li>
					<a [routerLink]="'frame.footer.contact.email' | msgKey">
						{{ 'frame.footer.contact' | msgKeyCached }}
					</a>
				</li>
				<li>
					<a href="https://amisonline.allianz.de/"
					   target="_blank">
						AMIS Online
					</a>
				</li>
				<li>
					<a href="https://www.allianz.de/impressum/"
					   target="_blank">
						{{ 'frame.footer.impressum' | msgKeyCached }}
					</a>
				</li>
				<li>
					<bas-footer-terms-of-use></bas-footer-terms-of-use>
				</li>
				<li>
					<a routerLink="/help.do?currentTopic=homepage/portalverantwortung">
						Portalverantwortung
					</a>
				</li>
			</ul>
			<ul>
				<li>
					<bas-footer-history></bas-footer-history>
				</li>
				<li>
					<bas-footer-privacy-policy></bas-footer-privacy-policy>
				</li>
			</ul>
		</div>
		<div class="bottom">
			<bas-footer-serverinfo></bas-footer-serverinfo>
		</div>
	</ng-container>
</footer>

<ng-template #notLoggedIn>
	<!-- 	page[@module='login' or @module='invalid' or @type='noframes'] -->
	<div class="bottom overlap">
		<div class="footer__legal">
			<h2>{{ 'login.text.legal' | msgKeyCached }}</h2>
			<a routerLink="/help.do?currentTopic=homepage/portalverantwortung">
				Portalverantwortung
			</a>
			<bas-footer-impressum></bas-footer-impressum>
			<bas-footer-privacy-policy></bas-footer-privacy-policy>
		</div>
		<div class="footer__hotline">
			<div class="footer__hotline__info">
				<h2>Hotline</h2>
				<p>Unsere Hotline erreichen Sie</p>
				<p>Montag bis Freitag<br><b>8:00 - 18:00 Uhr</b></p>
			</div>
			<div class="footer__hotline__cta">
				<p><a href="tel:{{ 'hotline.telefon.secondlevel' | msgKeyCached }}"><mat-icon>call</mat-icon> TELEFON <b>{{ 'hotline.telefon.secondlevel' | msgKeyCached }}</b></a></p>
				<p><a href="mailto:{{ 'hotline.email' | msgKeyCached }}"><mat-icon>mail</mat-icon> SCHREIBEN SIE UNS</a></p>
			</div>
		</div>
	</div>
	<!-- 	page -->
</ng-template>